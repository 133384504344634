<template>
  <div class="record">
    <header-search
      ref="headerSearch"
      placeholder="输入供应商名称搜索"
      @search="search"
    />
    <van-list
      v-if="recordList.length > 0"
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
      :immediate-check="false"
      class="record-list st-wrapper"
    >
      <div
        class="record-item"
        v-for="(record, record_index) in recordList"
        :key="record_index"
      >
        <span class="entry-time">进场时间：{{ record.newDay }}</span>
        <div class="market-list">
          <div
            class="st-item"
            v-for="(supplier, supplier_index) in record.data"
            :key="supplier_index"
            @click="handleEditSupplier(supplier)"
          >
            <div class="name">{{ supplier.supplier }}</div>
            <div class="bottom">
              <div class="goods">{{ supplier.items || '暂无食材' }}</div>
              <div class="type">{{ supplier.catalog }}</div>
            </div>
          </div>
        </div>
      </div>
    </van-list>
    <empty
      icon="record"
      text="暂无录入记录"
      height="calc(100vh - 3rem)"
      v-else
    />
    <footer-button text="新增录入信息" @confirm="confirm" />
  </div>
</template>
<script>
import { SwipeCell, Dialog, List } from 'vant'
import HeaderSearch from '../../../../components/header/search/Index'
import FooterButton from '../../../../components/footer/button/Index'
import Empty from '../../../../components/empty/Index'
export default {
  components: {
    [SwipeCell.name]: SwipeCell,
    [Dialog.name]: Dialog,
    [List.name]: List,
    HeaderSearch,
    FooterButton,
    Empty
  },
  data () {
    return {
      recordList: [],
      finished: false,
      loading: false,
      sendData: {
        supplier: '',
        page: 1,
        limit: 10
      }
    }
  },
  created () {
    this.getRecordList()
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    // 下拉加载更多
    onLoad () {
      this.sendData.page++
      this.getRecordList()
    },
    // 获取供应商列表
    getRecordList () {
      // console.log(this.$route.query.bid)
      this.$api.supplier.recordList({
        bid: this.$route.query.bid,
        ...this.sendData
      }).then(res => {
        if (res.data.success) {
          const data = res.data
          console.log('录入记录:', data)
          // 加载状态结束
          this.loading = false
          if (data.data.length === 0) {
            // 加载结束
            this.finished = true
            return
          }
          const list = data.data.map(item => {
            const strDay = item.day.toString()
            const newDay = strDay.substring(0, 4) + '-' + strDay.substring(4, 6) + '-' + strDay.substring(6, 9)
            item.data.map(supplier => {
              if (supplier.items) {
                supplier.items = supplier.items.map(items => {
                  return items.name
                }).join('、')
              }
              return supplier
            })
            return {
              ...item,
              newDay
            }
          })
          this.recordList = this.recordList.concat(list)
        }
      })
    },
    handleEditSupplier (item) {
      this.$router.push({
        name: 'AddSuppliers',
        query: {
          bid: item.bid,
          bno: item.bno,
          supplyId: item.supplyId
        }
      })
    },
    handleScroll () {
      // 获取滚动时的高度
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      const dom = this.$refs.headerSearch.$el.querySelector('.search-item')
      if (scrollTop >= 20) {
        dom.style.top = '0'
      } else {
        dom.style.top = ''
      }
    },
    search (value) {
      this.recordList = []
      // console.log(value)
      this.sendData.supplier = value
      this.getRecordList()
    },
    confirm () {
      this.$router.push({
        name: 'AddSuppliers',
        query: {
          bid: this.$route.query.bid
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
